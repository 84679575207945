
<template>

  <!-- <b-card-code class=""> -->
  <div class="d-flex flex-wrap" style="gap:20px">
      <b-card
        :title="offer.name"
        :img-src="offer.image"
        img-alt="image"
        img-top
        tag="article"
        class="mb-2 offer-card"
        v-for="(offer,index) in allOffer" :key="index">
        <div>
        <b-card-text>
         {{ offer.description }}
        </b-card-text>
        <b-card-text>
         <b>End date:</b> {{ offer.enddate  && offer.enddate!= '0000-00-00'? moment(offer.enddate).format('DD/MM/YYYY'):'-' }}
        </b-card-text>
        <b-card-text>
         <b>{{ offer.type }}:</b> {{ offer.value}} %
        </b-card-text>
        <b-card-text v-if="offer.projects && offer.projects.length>0">
         <b>Applied for projects:</b>
         <ul class="ml-2">
          <li v-for="(project,index) in offer.projects" :key="index"> {{ project.projectname }}</li>
         </ul>
        </b-card-text>
        </div>

        <b-button variant="primary" size='sm' @click="$router.push('/crm/sales/addsales')">Add Sales</b-button>
      </b-card>
  </div>
  <!-- </b-card-code> -->
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BImg,BCard, BCardText, BButton } from 'bootstrap-vue'
import axios from 'axios';
import moment from 'moment'
export default {
  components: {
    BCardCode,
    BImg,
    BButton,
    BCard,
    BCardText
  },
  data () {
    return {
      moment,
      allOffer: [],
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
    }
  },
  mounted() {
    this.getOffers();
  },
   computed:{
    setProject() {
      return (row) => {
        let str = ''
        if (row.projects.length > 0){
          let arr = row.projects.map((item) => item.projectname ? item.projectname : '')
          arr = arr.filter(item => item);
          str = arr.join('<br>')
        }
        return str
      }
    }
  },
  methods: {
    async getOffers() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/get-offers`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.allOffer = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    }
  }
}
</script>
<style>
.offer-card{
  width: 300px;
  height: 100%;
}
</style>
